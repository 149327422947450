import { StatusBar } from 'expo-status-bar';
import { Dimensions, StyleSheet, Text, View, Modal, Linking } from 'react-native';
import React, { useEffect, useState, useRef } from "react";
import Firebase, { database } from './Firebase';
import { ref, get, onValue } from 'firebase/database';
import { TouchableOpacity, Image, TextInput } from 'react-native-web';
import { AntDesign } from '@expo/vector-icons'; 
import {BrowserView, MobileView, isMobile} from 'react-device-detect';

const containerWidth = 1000;
const containerHeight = containerWidth * 0.3125;
const blueScreenColor = '#150DF7';

const colorBLUE = "#0077ff";
const colorORANGE = "#ff8800";
const colorGREEN = "#008000";

const sponsor = "Datadart";

export default function App() {

  const [matchPath, setMatchPath] = useState("live_matches/" + "DDeeZ6PlBldn0tgL7qfZ7rj110V2" + "/");
  const [matchData, setMatchData] = useState(null);

  const [activationCode, setActivationCode] = useState(null);
  const [usesMobile, setUsesMobile] = useState(false);

  const [payed, setPayed] = useState(false);
  const [streamingPicked, setStreamingPicked] = useState(false);
  const [paymentInformationOpen, setPaymentInformationOpen] = useState(false);

  const [messageModal, setMessageModal] = useState(false);
  const [messageModalHeadline, setMessageModalHeadline] = useState("");
  const [messageModalText, setMessageModalText] = useState("");

  const [username, setUsername] = useState("");
  const [uid, setUid] = useState(null);
  const [eventName, setEventName] = useState("");
  const [roundName, setRoundName] = useState("");
  const [showStats, setShowStats] = useState(true);
  const [showBlueScreen, setShowBlueScreen] = useState(true);
  const [showDartsInLeg, setShowDartsInLeg] = useState(false);

  const [playerOne, setPlayerOne] = useState("Player 1");
  const [playerTwo, setPlayerTwo] = useState("Player 2");
  const [scorePlayerOne, setScorePlayerOne] = useState(501);
  const [scorePlayerTwo, setScorePlayerTwo] = useState(501);
  const [legsPlayerOne, setLegsPlayerOne] = useState(0);
  const [legsPlayerTwo, setLegsPlayerTwo] = useState(0);
  const [setsPlayerOne, setSetsPlayerOne] = useState(0);
  const [setsPlayerTwo, setSetsPlayerTwo] = useState(0);
  const [playerOneStats, setPlayerOneStats] = useState({});
  const [playerOneScores, setPlayerOneScores] = useState({});
  const [playerTwoStats, setPlayerTwoStats] = useState({});
  const [playerTwoScores, setPlayerTwoScores] = useState({});
  const [dartsInThisLegPlayerOne, setDartsInThisLegPlayerOne] = useState(0);
  const [dartsInThisLegPlayerTwo, setDartsInThisLegPlayerTwo] = useState(0);

  const [status, setStatus] = useState("waiting");
  const [currentPlayer, setCurrentPlayer] = useState(1);
  const [startingPlayer, setStartingPlayer] = useState(1);
  const [gameLengthLegs, setGameLengthLegs] = useState(null);
  const [gameLengthSets, setGameLengthSets] = useState(null);
  const [gameMode, setGameMode] = useState(1);
  const [gameLength, setGameLength] = useState(501);

  const [currentPlayerBoxMargin, setCurrentPlayerBoxMargin] = useState(78.125);

  function updateMatchData(data)
  {
    if(data !== null)
    {
      const player1_obj = data.data.player1_obj;
      const player2_obj = data.data.player2_obj;
      const gameInfo_obj = data.data.gameInfo_obj;

      setPlayerOne(data.player1);
      setPlayerTwo(data.player2);
      setStatus(data.live);
      setScorePlayerOne(player1_obj.score);
      setScorePlayerTwo(player2_obj.score);
      setLegsPlayerOne(player1_obj.legsWon);
      setLegsPlayerTwo(player2_obj.legsWon);
      setSetsPlayerOne(player1_obj.setsWon);
      setSetsPlayerTwo(player2_obj.setsWon);
      setPlayerOneStats(player1_obj);
      setPlayerOneScores(player1_obj.stats);
      setPlayerTwoStats(player2_obj);
      setPlayerTwoScores(player2_obj.stats);

      if(player1_obj.score > 0 && player2_obj.score > 0)
      {
        updateCurrentPlayerBox(gameInfo_obj.currentPlayer);
        setDartsInThisLegPlayerOne(player1_obj.dartsInThisLeg);
        setDartsInThisLegPlayerTwo(player2_obj.dartsInThisLeg);
      }

      setCurrentPlayer(gameInfo_obj.currentPlayer);
      setStartingPlayer(gameInfo_obj.startingPlayer);
      setGameLengthLegs(gameInfo_obj.gameLengthLegs);
      setGameLengthSets(gameInfo_obj.gameLengthSets);
      setGameMode(gameInfo_obj.gameMode);
      setGameLength(gameInfo_obj.gameLength);
    }
  }

  function loadMatchData(uid) {
    const databaseRef = ref(database, ("live_matches/" + uid + "/"));
    onValue(databaseRef, snapshot => {
      const tempData = snapshot.val();
      if (tempData != null) {
        setUid(uid);
        setMatchPath("live_matches/" + uid + "/");
        updateMatchData(tempData);
        setMatchData(tempData);
      }
    });
  }

  function getCurrentStartingPlayer(player)
  {
    const currentLegsPlayed = legsPlayerOne + legsPlayerTwo;

    //Single Player
    if(gameMode === 0)
    {
      return player === 1 ? true : false;
    }

    if(player === 1)
    {
      if(currentLegsPlayed % 2 === 0)
      {
        if(startingPlayer === 1) { return true }
        else {return false }
      }
      else
      {
        if(startingPlayer === 1) { return false }
        else {return true }
      }
    }
    else
    {
      if(currentLegsPlayed % 2 === 0)
      {
        if(startingPlayer === 1) { return false }
        else {return true }
      }
      else
      {
        if(startingPlayer === 1) { return true }
        else {return false }
      }
    }
  }

  function updateCurrentPlayerBox(player)
  {
    if(player === 1)
    {
      setCurrentPlayerBoxMargin(78.125);
    }
    else
    {
      setCurrentPlayerBoxMargin(-78.125);
    }
  }

  function loadUID()
  {
    if(username === "" || username === null)
    {
      setMessageModalHeadline("No live data found");
      setMessageModalText("No live data was found to this username. Please make sure you enter a correct username for accessing live data.");
      setMessageModal(true);
      return;
    }

    const databaseRef = ref(database, ("usernames/" + (username !== null ? username : "nonsensenonsensenonsense")));
    onValue(databaseRef, snapshot => {
      const tempData = snapshot.val();
      if (tempData != null) {
        loadMatchData(tempData);
      }
      else
      {
        setMessageModalHeadline("No live data found");
        setMessageModalText("No live data was found to this username. Please make sure you enter a correct username for accessing live data.");
        setMessageModal(true);
      }
    });
  }

  function updateEventName(name)
  {
    setEventName(name);
  }

  function updateRoundName(round)
  {
    setRoundName(round);
  }

  function checkPayed()
  {
    const databaseRef = ref(database, ("activated_live_users/" + activationCode));
    onValue(databaseRef, snapshot => {
      const tempData = snapshot.val();
      if (tempData != null) {
        enterProfessional(tempData);
      }
      else
      {
        setMessageModalHeadline("No live data found");
        setMessageModalText("No live data was found to this username. Please make sure you enter a correct username for accessing live data. Are you sure that you have access to a MyDartCoach Live Professional account?");
        setMessageModal(true);
      }
    });
  }

  function enterProfessional(data)
  {
    setEventName(data.title);
    setPayed(true);
    setStreamingPicked(false);
  }

  function isNineDartFinishPossible(pointsLeft, throws)
  {
      if(throws === 1)
      {
          if(pointsLeft <= 350)
          {
              return true;
          }
          else
          {
              return false;
          }
  
      }
      else if(throws === 2)
      {
          if(pointsLeft <= 170)
          {
              return true;
          }
          else
          {
              return false;
          }
      }
      else
      {
          return false;
      }
  }

  //entitiy
  //  0: single / streamer
  //  1: group / club
  function openEmailForPurchase(entitiy)
  {
    if(entitiy === 0)
    {
      Linking.openURL("mailto:live@mydartcoach.com?subject=MyDartCoach Live Purchase&body=Please fill in the information so that we can contact you:%0D%0A%0D%0AYour firstname: %0D%0AYour lastname: %0D%0AYour address (Street, number, Postcode, City): %0D%0AInterested in: MyDartCoach Live Professional (for single users / streamers)%0D%0AYour email: %0D%0A%0D%0A")
    }
    else
    {
      Linking.openURL('mailto:live@mydartcoach.com?subject=MyDartCoach Live Purchase&body=Please fill in the information so that we can contact you:%0D%0A%0D%0AYour firstname: %0D%0AYour lastname: %0D%0AYour address (Street, number, Postcode, City): %0D%0AInterested in: MyDartCoach Live Professional (for groups / clubs)%0D%0AYour email: %0D%0A%0D%0A')
    }
  }

  //indicator
  //  0: Landing page
  //  1: Streaming & Professional
  //  2: MyDartCoach Live for Streamers Explanation
  function openInformation(indicator)
  {
    Linking.openURL("https://youtu.be/BuL0Mm8B-tQ");
  }


  useEffect(() => {
    if(isMobile === true)
    {
      setUsesMobile(true);
    }
  }, []);


  return (
    <View style={{...styles.container, backgroundColor: (payed === true ? (showBlueScreen === true ? blueScreenColor : "#535353") : "#1f1f1f")}}>

      <Modal
        animationType="slide"
        transparent={true}
        visible={messageModal}
        onRequestClose={() => {
          setMessageModal(!messageModal);
        }}>

        <View style={{width: Dimensions.get('window').width, height: Dimensions.get('window').height, backgroundColor: 'transparent', justifyContent: 'center', alignItems: 'center'}}>
          <View style={{width: '60%', height: '80%', backgroundColor: '#FAF9F6', borderRadius: 20, paddingHorizontal: 30, justifyContent: 'center', alignItems: 'center'}}>

            <TouchableOpacity onPress={() => setMessageModal(false)} style={{position: 'absolute', top: 10, right: 10}}>
              <AntDesign name="close" size={40} color="black" />
            </TouchableOpacity>

            <Text style={{fontSize: containerWidth / 20, color: "black", fontWeight: 'bold', textAlign: 'center'}}>
              {messageModalHeadline}
            </Text>

            <Text style={{fontSize: containerWidth / 30, color: "black", textAlign: 'center'}}>
              {messageModalText}
            </Text>

          </View>
        </View>

      </Modal>

      <Modal
        animationType="slide"
        transparent={true}
        visible={paymentInformationOpen}
        onRequestClose={() => {
          setPaymentInformationOpen(!modalVisible);
        }}>

        <View style={{width: Dimensions.get('window').width, height: Dimensions.get('window').height, backgroundColor: 'transparent', justifyContent: 'center', alignItems: 'center'}}>
          <View style={{width: '60%', height: '80%', backgroundColor: '#FAF9F6', borderRadius: 20, paddingHorizontal: 30, justifyContent: 'center', alignItems: 'center'}}>

            <TouchableOpacity onPress={() => setPaymentInformationOpen(false)} style={{position: 'absolute', top: 10, right: 10}}>
              <AntDesign name="close" size={40} color="black" />
            </TouchableOpacity>

            <View style={{width: '100%', height: '20%', justifyContent: 'center', alignItems: 'center'}}>

              <Text style={{fontSize: containerWidth / 32, color: "black", fontWeight: 'bold', textAlign: 'center'}}>
                {"Get access to MyDartCoach Live for Streamers / Professionals / Clubs and more"}
              </Text>

            </View>

            <View style={{width: '100%', height: '60%', justifyContent: 'center', alignItems: 'center', flexDirection: 'row'}}>

              <TouchableOpacity onPress={() => {openEmailForPurchase(0)}} style={{width: '35%', height: '90%', borderRadius: 10, backgroundColor: colorBLUE, marginRight: 10, justifyContent: 'center', alignItems: 'center'}}>
                
                <Text style={{fontSize: containerWidth / 40, color: "white", fontWeight: 'bold', position: 'absolute', top: 10}}>
                  {"Single User / Streamer"}
                </Text>

                <Text style={{fontSize: containerWidth / 10, color: "white", fontWeight: 'bold'}}>
                  {"49€"}
                </Text>

                <Text style={{fontSize: containerWidth / 40, color: "white", position: 'absolute', bottom: 10}}>
                  {"Lifetime access"}
                </Text>

              </TouchableOpacity>

              <TouchableOpacity onPress={() => {openEmailForPurchase(1)}} style={{width: '35%', height: '90%', borderRadius: 10, backgroundColor: colorORANGE, marginLeft: 10, justifyContent: 'center', alignItems: 'center'}}>
                
                <Text style={{fontSize: containerWidth / 40, color: "white", fontWeight: 'bold', position: 'absolute', top: 10}}>
                  {"Clubs / Groups"}
                </Text>

                <Text style={{fontSize: containerWidth / 10, color: "white", fontWeight: 'bold'}}>
                  {"99€"}
                </Text>

                <Text style={{fontSize: containerWidth / 40, color: "white", position: 'absolute', bottom: 10}}>
                  {"Lifetime access"}
                </Text>

              </TouchableOpacity>

            </View>

            <View style={{width: '100%', height: '20%', justifyContent: 'center', alignItems: 'center'}}>

              <Text style={{fontSize: containerWidth / 38, color: "black", textAlign: 'center'}}>
                {"Choose a plan for your MyDartCoach Live lifetime access. (Or contact us via email live@mydartcoach.com)"}
              </Text>

            </View>

          </View>
        </View>

      </Modal>

      {
        payed === false && uid === null ?

          (
            usesMobile === true ?

            <View style={{...styles.container, width: Dimensions.get('window').width}}>

              <View style={{width: '100%', height: containerHeight / 4, justifyContent: 'center', alignItems: 'center', paddingHorizontal: 10}}>

                <Text style={{fontSize: containerWidth / 32, color: "white", fontWeight: 'bold', textAlign: 'center'}}>
                  {"MyDartCoach Live"}
                </Text>

                <Text style={{fontSize: containerWidth / 50, color: "white", textAlign: 'center'}}>
                  {"MyDartCoach Live has not been developed and adapted for mobile devices. Some things may not look / work as you expect. Please open MyDartCoach Live on a larger and non-mobile device."}
                </Text>

                <TouchableOpacity style={{marginTop: 10}} onPress={() => {setUsesMobile(false)}}>

                  <Text style={{fontSize: containerWidth / 50, color: "white", textAlign: 'center', textDecorationLine: 'underline'}}>
                    {"Continue anyway"}
                  </Text>

                </TouchableOpacity>

              </View>

            </View>

            :

            <View style={{...styles.container, width: Dimensions.get('window').width}}>

              <TouchableOpacity onPress={() => openInformation(0)} style={{position: 'absolute', top: 20, right: 20, width: 50, height: 50, justifyContent: 'center', alignItems: 'center'}}>
                <AntDesign name="infocirlceo" size={40} color="white" />
              </TouchableOpacity>

              <View style={{width: '90%', height: containerHeight / 4, justifyContent: 'center', alignItems: 'center'}}>

                <Text style={{fontSize: containerWidth / 10, color: "white", fontWeight: 'bold'}}>
                  {"MyDartCoach Live"}
                </Text>

              </View>

              <View style={{width: '100%', height: containerHeight * 0.8, justifyContent: 'flex-end', alignItems: 'center'}}>

                <Text style={{fontSize: containerWidth / 22, color: "white"}}>
                  {"Enter a username"}
                </Text>

                <TextInput
                  style={{height: 50, width: 300, borderBottomColor: 'white', borderBottomWidth: 2, marginTop: 5, color: 'white'}}
                  placeholder="Username"
                  onChangeText={newText => setUsername(newText)}
                  defaultValue={username === null ? "" : username}
                  placeholderTextColor="gray" 
                />

              </View>

              <View style={{width: '100%', height: containerHeight, justifyContent: 'center', alignItems: 'center', flexDirection: 'row'}}>

                <TouchableOpacity onPress={() => loadUID()} style={{width: 250, height: 250, marginRight: 20, borderRadius: 10, backgroundColor: colorGREEN, marginTop: 30, justifyContent: 'center', alignItems: 'center'}}>

                  <Text style={{fontSize: containerWidth / 26, color: "white", fontWeight: "bold", textAlign: 'center'}}>
                    {"Free\nVersion"}
                  </Text>

                </TouchableOpacity>

                <TouchableOpacity onPress={() => {loadUID(), setStreamingPicked(true)}} style={{width: 250, height: 250, marginLeft: 20, borderRadius: 10, backgroundColor: colorORANGE, marginTop: 30, justifyContent: 'center', alignItems: 'center'}}>

                  <Text style={{fontSize: containerWidth / 26, color: "white", fontWeight: "bold", textAlign: 'center'}}>
                    {"Streaming &\nProfessionals"}
                  </Text>

                </TouchableOpacity>


              </View>

            </View>
          )

        :

          streamingPicked === true ?

          <View style={{...styles.container, width: Dimensions.get('window').width}}>
            
            <TouchableOpacity onPress={() => openInformation(1)} style={{position: 'absolute', top: 20, right: 20, width: 50, height: 50, justifyContent: 'center', alignItems: 'center'}}>
              <AntDesign name="infocirlceo" size={40} color="white" />
            </TouchableOpacity>

            <TouchableOpacity onPress={() => {setStreamingPicked(false), setUid(null)}} style={{position: 'absolute', top: 20, left: 20, width: 50, height: 50, justifyContent: 'center', alignItems: 'center'}}>
              <AntDesign name="back" size={40} color="white" />
            </TouchableOpacity>

            <View style={{width: '90%', height: containerHeight / 4}}/>

            <View style={{width: '90%', height: containerHeight / 4, justifyContent: 'center', alignItems: 'center'}}>

              <Text style={{fontSize: containerWidth / 14, color: "white", fontWeight: 'bold'}}>
                {"MyDartCoach Live"}
              </Text>

              <Text style={{fontSize: containerWidth / 20, color: colorORANGE}}>
                {"for Streaming & Professionals"}
              </Text>

            </View>

            <View style={{width: '100%', height: containerHeight / 4}}/>

            <View style={{width: '100%', height: containerHeight * 2, justifyContent: 'flex-start', alignItems: 'center', flexDirection: 'row'}}>

              <View style={{height: '100%', width: '50%', justifyContent: 'flex-start', alignItems: 'center'}}>

                <Text style={{fontSize: containerWidth / 22, color: "white"}}>
                  {"Enter your activation code"}
                </Text>

                <TextInput
                  style={{height: 50, width: 300, borderBottomColor: 'white', borderBottomWidth: 2, marginTop: 5, color: 'white', marginBottom: 5}}
                  placeholder="Activation Code"
                  onChangeText={code => setActivationCode(code)}
                  defaultValue={activationCode === null ? "" : activationCode}
                  placeholderTextColor="gray" 
                />

                <TouchableOpacity onPress={() => {setPaymentInformationOpen(true)}} style={{}}>

                  <Text style={{fontSize: containerWidth / 50, color: "white", textAlign: 'center', textDecorationLine: "underline", textDecorationStyle: "solid", textDecorationColor: "white"}}>
                    {"Don't have an activation code?"}
                  </Text>

                </TouchableOpacity>

                <TouchableOpacity onPress={() => checkPayed()} style={{width: '80%', maxWidth: 500, height: 60, marginRight: 20, borderRadius: 10, backgroundColor: colorGREEN, justifyContent: 'center', alignItems: 'center', marginTop: 50}}>

                <Text style={{fontSize: containerWidth / 40, color: "white", fontWeight: "bold", textAlign: 'center'}}>
                  {"Load MyDartCoach Live"}
                </Text>

              </TouchableOpacity>

              </View>

              <View style={{height: '100%', width: '45%', justifyContent: 'flex-start', alignItems: 'center', backgroundColor: '#3d3d3d', borderRadius: 10}}>

                <Text style={{fontSize: containerWidth / 24, color: "white", fontWeight: 'bold'}}>
                  {"Settings:"}
                </Text>

                <View style={{height: 40, width: '100%', marginTop: 20, justifyContent: 'center', alignItems: 'center'}}>
                  <Text style={{fontSize: containerWidth / 36, color: "white"}}>
                    {"Show Stats (for commentary):"}
                  </Text>
                </View>

                <View style={{height: 40, width: '100%', justifyContent: 'space-evenly', alignItems: 'center', flexDirection: 'row'}}>
                  
                  <TouchableOpacity onPress={() => setShowStats(true)} style={{height: '100%', borderRadius: 5, backgroundColor: (showStats === true ? colorBLUE : 'gray'), justifyContent: 'center', alignItems: 'center', paddingHorizontal: 10}}>
                    <Text style={{fontSize: containerWidth / 40, color: "white", fontWeight: "bold"}}>
                      {"Show stats"}
                    </Text>
                  </TouchableOpacity>

                  <TouchableOpacity onPress={() => setShowStats(false)} style={{height: '100%', borderRadius: 5, backgroundColor: (showStats === false ? colorBLUE : 'gray'), justifyContent: 'center', alignItems: 'center', paddingHorizontal: 10}}>
                    <Text style={{fontSize: containerWidth / 40, color: "white", fontWeight: "bold"}}>
                      {"Without"}
                    </Text>
                  </TouchableOpacity>

                </View>

                <View style={{height: 40, width: '100%', marginTop: 20, justifyContent: 'center', alignItems: 'center'}}>
                  <Text style={{fontSize: containerWidth / 36, color: "white"}}>
                    {"Use BlueScreen (for streaming):"}
                  </Text>
                </View>

                <View style={{height: 40, width: '100%', justifyContent: 'space-evenly', alignItems: 'center', flexDirection: 'row'}}>
                  
                  <TouchableOpacity onPress={() => setShowBlueScreen(true)} style={{height: '100%', borderRadius: 5, backgroundColor: (showBlueScreen === true ? colorBLUE : 'gray'), justifyContent: 'center', alignItems: 'center', paddingHorizontal: 10}}>
                    <Text style={{fontSize: containerWidth / 40, color: "white", fontWeight: "bold"}}>
                      {"Use BlueScreen"}
                    </Text>
                  </TouchableOpacity>

                  <TouchableOpacity onPress={() => setShowBlueScreen(false)} style={{height: '100%', borderRadius: 5, backgroundColor: (showBlueScreen === false ? colorBLUE : 'gray'), justifyContent: 'center', alignItems: 'center', paddingHorizontal: 10}}>
                    <Text style={{fontSize: containerWidth / 40, color: "white", fontWeight: "bold"}}>
                      {"Without"}
                    </Text>
                  </TouchableOpacity>

                </View>

                <View style={{height: 40, width: '100%', marginTop: 20, justifyContent: 'center', alignItems: 'center'}}>
                  <Text style={{fontSize: containerWidth / 36, color: "white"}}>
                    {"Show Darts in Leg:"}
                  </Text>
                </View>

                <View style={{height: 40, width: '100%', justifyContent: 'space-evenly', alignItems: 'center', flexDirection: 'row'}}>
                  
                  <TouchableOpacity onPress={() => setShowDartsInLeg(true)} style={{height: '100%', borderRadius: 5, backgroundColor: (showDartsInLeg === true ? colorBLUE : 'gray'), justifyContent: 'center', alignItems: 'center', paddingHorizontal: 10}}>
                    <Text style={{fontSize: containerWidth / 40, color: "white", fontWeight: "bold"}}>
                      {"Show Darts/Leg"}
                    </Text>
                  </TouchableOpacity>

                  <TouchableOpacity onPress={() => setShowDartsInLeg(false)} style={{height: '100%', borderRadius: 5, backgroundColor: (showDartsInLeg === false ? colorBLUE : 'gray'), justifyContent: 'center', alignItems: 'center', paddingHorizontal: 10}}>
                    <Text style={{fontSize: containerWidth / 40, color: "white", fontWeight: "bold"}}>
                      {"Without"}
                    </Text>
                  </TouchableOpacity>

                </View>

                <View style={{height: 40, width: '100%', marginTop: 20, justifyContent: 'center', alignItems: 'center'}}>
                  <Text style={{fontSize: containerWidth / 36, color: "white"}}>
                    {"Tournament name:"}
                  </Text>
                </View>

                <View style={{height: 40, width: '100%', justifyContent: 'space-evenly', alignItems: 'center', flexDirection: 'row'}}>
                  
                  <TouchableOpacity onPress={() => updateEventName("Your personal title")} style={{height: '100%', borderRadius: 5, backgroundColor: (eventName == "Your personal title" ? colorBLUE : 'gray'), justifyContent: 'center', alignItems: 'center', paddingHorizontal: 10}}>
                    <Text style={{fontSize: containerWidth / 40, color: "white", fontWeight: "bold"}}>
                      {"Your personal title"}
                    </Text>
                  </TouchableOpacity>

                  <TouchableOpacity onPress={() => updateEventName("")} style={{height: '100%', borderRadius: 5, backgroundColor: (eventName === "" ? colorBLUE : 'gray'), justifyContent: 'center', alignItems: 'center', paddingHorizontal: 10}}>
                    <Text style={{fontSize: containerWidth / 40, color: "white", fontWeight: "bold"}}>
                      {"Without"}
                    </Text>
                  </TouchableOpacity>

                </View>

                <View style={{height: 40, width: '100%', marginTop: 20, justifyContent: 'center', alignItems: 'center'}}>
                  <Text style={{fontSize: containerWidth / 36, color: "white"}}>
                    {"Tournament round:"}
                  </Text>
                </View>

                <View style={{height: 40, width: '100%', justifyContent: 'space-evenly', alignItems: 'center', flexDirection: 'row'}}>
                  
                  <TouchableOpacity onPress={() => updateRoundName("Group Stage")} style={{height: '100%', borderRadius: 5, backgroundColor: (roundName == "Group Stage" ? colorBLUE : 'gray'), justifyContent: 'center', alignItems: 'center', paddingHorizontal: 10}}>
                    <Text style={{fontSize: containerWidth / 40, color: "white", fontWeight: "bold"}}>
                      {"Group Stage"}
                    </Text>
                  </TouchableOpacity>

                  <TouchableOpacity onPress={() => updateRoundName("L128")} style={{height: '100%', borderRadius: 5, backgroundColor: (roundName == "L128" ? colorBLUE : 'gray'), justifyContent: 'center', alignItems: 'center', paddingHorizontal: 10}}>
                    <Text style={{fontSize: containerWidth / 40, color: "white", fontWeight: "bold"}}>
                      {"L128"}
                    </Text>
                  </TouchableOpacity>

                  <TouchableOpacity onPress={() => updateRoundName("L64")} style={{height: '100%', borderRadius: 5, backgroundColor: (roundName == "L64" ? colorBLUE : 'gray'), justifyContent: 'center', alignItems: 'center', paddingHorizontal: 10}}>
                    <Text style={{fontSize: containerWidth / 40, color: "white", fontWeight: "bold"}}>
                      {"L64"}
                    </Text>
                  </TouchableOpacity>

                  <TouchableOpacity onPress={() => updateRoundName("L32")} style={{height: '100%', borderRadius: 5, backgroundColor: (roundName == "L32" ? colorBLUE : 'gray'), justifyContent: 'center', alignItems: 'center', paddingHorizontal: 10}}>
                    <Text style={{fontSize: containerWidth / 40, color: "white", fontWeight: "bold"}}>
                      {"L32"}
                    </Text>
                  </TouchableOpacity>

                  <TouchableOpacity onPress={() => updateRoundName("L16")} style={{height: '100%', borderRadius: 5, backgroundColor: (roundName == "L16" ? colorBLUE : 'gray'), justifyContent: 'center', alignItems: 'center', paddingHorizontal: 10}}>
                    <Text style={{fontSize: containerWidth / 40, color: "white", fontWeight: "bold"}}>
                      {"L16"}
                    </Text>
                  </TouchableOpacity>

                </View>

                <View style={{height: 40, width: '100%', justifyContent: 'space-evenly', alignItems: 'center', flexDirection: 'row', marginTop: 10}}>

                  <TouchableOpacity onPress={() => updateRoundName("Quarterfinal")} style={{height: '100%', borderRadius: 5, backgroundColor: (roundName == "Quarterfinal" ? colorBLUE : 'gray'), justifyContent: 'center', alignItems: 'center', paddingHorizontal: 10}}>
                    <Text style={{fontSize: containerWidth / 40, color: "white", fontWeight: "bold"}}>
                      {"Quarterfinal"}
                    </Text>
                  </TouchableOpacity>

                  <TouchableOpacity onPress={() => updateRoundName("Semifinal")} style={{height: '100%', borderRadius: 5, backgroundColor: (roundName == "Semifinal" ? colorBLUE : 'gray'), justifyContent: 'center', alignItems: 'center', paddingHorizontal: 10}}>
                    <Text style={{fontSize: containerWidth / 40, color: "white", fontWeight: "bold"}}>
                      {"Semifinal"}
                    </Text>
                  </TouchableOpacity>

                  <TouchableOpacity onPress={() => updateRoundName("Final")} style={{height: '100%', borderRadius: 5, backgroundColor: (roundName == "Final" ? colorBLUE : 'gray'), justifyContent: 'center', alignItems: 'center', paddingHorizontal: 10}}>
                    <Text style={{fontSize: containerWidth / 40, color: "white", fontWeight: "bold"}}>
                      {"Final"}
                    </Text>
                  </TouchableOpacity>

                  <TouchableOpacity onPress={() => updateRoundName("")} style={{height: '100%', borderRadius: 5, backgroundColor: (roundName == "" ? colorBLUE : 'gray'), justifyContent: 'center', alignItems: 'center', paddingHorizontal: 10}}>
                    <Text style={{fontSize: containerWidth / 40, color: "white", fontWeight: "bold"}}>
                      {"Without"}
                    </Text>
                  </TouchableOpacity>

                </View>

              </View>
            
            </View>

            <View style={{width: '100%', height: containerHeight / 4}}/>


          </View>

          :

            payed === true ?

              <View style={{...styles.container, backgroundColor: showBlueScreen === true ? blueScreenColor : "#535353"}}>

                <TouchableOpacity onPress={() => {setPayed(false), setStreamingPicked(true)}} style={{position: 'absolute', top: 20, left: 20, width: 50, height: 50, justifyContent: 'center', alignItems: 'center'}}>
                  <AntDesign name="back" size={40} color="white" />
                </TouchableOpacity>

                <TouchableOpacity onPress={() => openInformation(2)} style={{position: 'absolute', top: 20, right: 20, width: 50, height: 50, justifyContent: 'center', alignItems: 'center'}}>
                  <AntDesign name="infocirlceo" size={40} color="white" />
                </TouchableOpacity>

                <View style={{width: '90%', height: containerHeight, justifyContent: 'center', alignItems: 'center'}}>

                  <Text style={{fontSize: containerWidth / 16, color: "white", fontWeight: 'bold'}}>
                    {playerOne + " vs. " + playerTwo}
                  </Text>

                  <Text style={{fontSize: containerWidth / 22, color: "white"}}>
                    {"Status of the match: " + status}
                  </Text>

                </View>

                <View style={{width: '100%', height: containerHeight, justifyContent: 'center', alignItems: 'center', flexDirection: 'row'}}>

                  <View style={{flexDirection: 'column'}}>

                  {
                    (isNineDartFinishPossible(scorePlayerOne, dartsInThisLegPlayerOne/3) && gameLength === 501) === true ?

                    <View style={{...styles.currentPlayerBox, backgroundColor: 'yellow', height: containerHeight * 0.25, width: containerHeight * 0.25, marginBottom: 0}}>
                      <Text
                        style={{
                            color: (isNineDartFinishPossible(scorePlayerOne, dartsInThisLegPlayerOne/3) && gameLength === 501) ? "black" : "transparent",
                            fontWeight: "bold",
                            fontSize: (isNineDartFinishPossible(scorePlayerOne, dartsInThisLegPlayerOne/3) && gameLength === 501) ? containerWidth / 20 : 0,
                            paddingHorizontal: (isNineDartFinishPossible(scorePlayerOne, dartsInThisLegPlayerOne/3) && gameLength === 501) ? 2 : 0,
                            textAlign: 'center',
                            marginLeft: 5,
                        }}
                        >
                            9
                        </Text>
                    </View>

                    :

                    <View style={{height: containerHeight * 0.25, width: containerHeight * 0.25}}/>
                  }

                  {
                    (isNineDartFinishPossible(scorePlayerTwo, dartsInThisLegPlayerTwo/3) && gameLength === 501) === true ?

                    <View style={{...styles.currentPlayerBox, backgroundColor: 'yellow', height: containerHeight * 0.25, width: containerHeight * 0.25, marginBottom: 0}}>
                      <Text
                        style={{
                            color: (isNineDartFinishPossible(scorePlayerTwo, dartsInThisLegPlayerTwo/3) && gameLength === 501) ? "black" : "transparent",
                            fontWeight: "bold",
                            fontSize: (isNineDartFinishPossible(scorePlayerTwo, dartsInThisLegPlayerTwo/3) && gameLength === 501) ? containerWidth / 20 : 0,
                            paddingHorizontal: (isNineDartFinishPossible(scorePlayerTwo, dartsInThisLegPlayerTwo/3) && gameLength === 501) ? 2 : 0,
                            textAlign: 'center',
                            marginLeft: 5,
                        }}
                        >
                            9
                        </Text>
                    </View>

                    :

                    <View style={{height: containerHeight * 0.25, width: containerHeight * 0.25}}/>
                  }

                  </View>


                  <View style={styles.x01Container}>

                    <View style={styles.outsideBox}>

                      <View style={{...styles.playerBox, width: (gameLengthSets > 1 ? '55%' : '70%')}}>

                        <Text style={{fontSize: containerWidth / 26, color: "white", paddingLeft: 20}}>
                          {"Best of " + gameLengthLegs + " Legs" + (gameLengthSets > 1 ? (" & " +  gameLengthSets + " Sets") : "")}
                        </Text>

                        <Text style={{fontSize: containerWidth / 26, color: "white", position: 'absolute', right: 10}}>
                          {roundName}
                        </Text>

                      </View>

                      <View style={{...styles.scoreBox, backgroundColor: 'transparent', justifyContent: 'center', width: (gameLengthSets > 1 ? '45%' : '30%')}}>

                        <View style={{...styles.halfScoreBox, width: (gameLengthSets > 1 ? '33.33%' : '50%')}}>

                          <Text style={{fontSize: containerWidth / 26, color: "white"}}>
                            {"Legs"}
                          </Text>

                        </View>

                        
                        {
                          gameLengthSets > 1 ?

                          <View style={{...styles.halfScoreBox, width: (gameLengthSets > 1 ? '33.33%' : '50%')}}>

                            <Text style={{fontSize: containerWidth / 26, color: "white"}}>
                              {"Sets"}
                            </Text>

                          </View>

                          :

                          <View/>
                        }

                        <View style={{...styles.halfScoreBox, width: (gameLengthSets > 1 ? '33.33%' : '50%')}}/>

                      </View>

                    </View>

                    <View style={styles.insideBox}>

                      <View style={{...styles.playerBox, width: (gameLengthSets > 1 ? '55%' : '70%')}}>

                        <Text style={{fontWeight: 'bold', fontSize: containerWidth / 20, color: "black", paddingLeft: 20}}>
                          {playerOne}
                        </Text>

                        <Text style={{fontSize: containerWidth / 24, color: "black", position: 'absolute', right: 60, paddingBottom: 5}}>
                          {showDartsInLeg === true ? "("+dartsInThisLegPlayerOne+")" : ""}
                        </Text>

                        <View style={{width: containerWidth / 40, height: containerWidth / 40, backgroundColor: ((getCurrentStartingPlayer(1) === true && status === "running") ? '#8F2222' : 'transparent'), borderRadius:  containerWidth / 80, position: 'absolute', right: 20}} />

                      </View>

                      <View style={{...styles.scoreBox, width: (gameLengthSets > 1 ? '45%' : '30%')}}>

                        <View style={{...styles.halfScoreBox, width: (gameLengthSets > 1 ? '33.33%' : '50%')}}>

                          <Text style={{fontWeight: 'bold', fontSize: containerWidth / 20, color: "white"}}>
                            {legsPlayerOne}
                          </Text>

                        </View>

                        {
                          gameLengthSets > 1 ?

                          <View style={{...styles.halfScoreBox, width: (gameLengthSets > 1 ? '33.33%' : '50%')}}>

                            <Text style={{fontWeight: 'bold', fontSize: containerWidth / 20, color: "white"}}>
                              {setsPlayerOne}
                            </Text>

                          </View>

                          :

                          <View/>
                        }

                        <View style={{...styles.halfScoreBox, width: (gameLengthSets > 1 ? '33.33%' : '50%')}}>

                          <Text style={{fontWeight: 'bold', fontSize: containerWidth / 20, color: "white"}}>
                            {scorePlayerOne}
                          </Text>

                        </View>

                      </View>

                    </View>

                    <View style={styles.insideBox}>

                      <View style={{...styles.playerBox, width: (gameLengthSets > 1 ? '55%' : '70%')}}>

                        <Text style={{fontWeight: 'bold', fontSize: containerWidth / 20, color: "black", paddingLeft: 20}}>
                          {gameMode === 0 ? "" : playerTwo}
                        </Text>

                        <Text style={{fontSize: containerWidth / 24, color: "black", position: 'absolute', right: 60, paddingBottom: 5}}>
                          {showDartsInLeg === true && gameMode > 0 ? "("+dartsInThisLegPlayerTwo+")" : ""}
                        </Text>

                        <View style={{width: containerWidth / 40, height: containerWidth / 40, backgroundColor: ((getCurrentStartingPlayer(2) === true && status === "running") ? '#8F2222' : 'transparent'), borderRadius:  containerWidth / 80, position: 'absolute', right: 20}} />

                      </View>

                      <View style={{...styles.scoreBox, width: (gameLengthSets > 1 ? '45%' : '30%')}}>

                        <View style={{...styles.halfScoreBox, width: (gameLengthSets > 1 ? '33.33%' : '50%')}}>

                          <Text style={{fontWeight: 'bold', fontSize: containerWidth / 20, color: "white"}}>
                            {gameMode === 0 ? "" : legsPlayerTwo}
                          </Text>

                        </View>

                        {
                          gameLengthSets > 1 ?

                          <View style={{...styles.halfScoreBox, width: (gameLengthSets > 1 ? '33.33%' : '50%')}}>

                            <Text style={{fontWeight: 'bold', fontSize: containerWidth / 20, color: "white"}}>
                              {gameMode === 0 ? "" : setsPlayerTwo}
                            </Text>

                          </View>

                          :

                          <View/>
                        }

                        <View style={{...styles.halfScoreBox, width: (gameLengthSets > 1 ? '33.33%' : '50%')}}>

                          <Text style={{fontWeight: 'bold', fontSize: containerWidth / 20, color: "white"}}>
                            {gameMode === 0 ? "" : scorePlayerTwo}
                          </Text>

                        </View>

                      </View>

                    </View>

                    <View style={styles.outsideBox} >

                      <View style={styles.playerBox}>

                        <Text style={{fontSize: containerWidth / 28, color: "white", paddingLeft: 20}}>
                          {eventName}
                        </Text>

                      </View>

                      <View style={{...styles.scoreBox, backgroundColor: 'transparent', justifyContent: 'center', alignItems: 'center'}}>

                        <Image source={require('./assets/MyDartCoach_white.png')}
                          style={{
                              aspectRatio: 2,
                              width: 250,
                              height: null,
                              resizeMode: 'contain',
                              borderRadius: 0,
                          }}
                        />

                      </View>

                    </View>

                  </View>

                  {
                    status === "running" ?

                    <View style={{...styles.currentPlayerBox, height: containerHeight * 0.25, width: containerHeight * 0.25, paddingRight: 10, marginBottom: currentPlayerBoxMargin}}>
                        <View style={{...styles.triangle, borderLeftWidth: 25, borderRightWidth: 25, borderBottomWidth: 50}} />
                    </View>

                    :

                    <View style={{height: containerHeight * 0.25, width: containerHeight * 0.25}}/>
                  }


                </View>

                <View style={{width: '100%', height: containerHeight*1.5, justifyContent: 'center', alignItems: 'center', flexDirection: 'row', marginTop: 20}}>

                  {
                    showStats === true ?

                    <View style={{width: '33%', height: '100%', alignItems: 'center'}}>

                      <Text style={{fontSize: containerWidth / 26, color: "white", fontWeight: 'bold'}}>
                        {playerOne}
                      </Text>

                      <Text style={{fontSize: containerWidth / 30, color: "white"}}>
                        {"Avg: " + playerOneStats.average}
                      </Text>

                      <Text style={{fontSize: containerWidth / 30, color: "white"}}>
                        {"DQ: " + playerOneStats.doubleQuota + "%"}
                      </Text>

                      <Text style={{fontSize: containerWidth / 30, color: "white"}}>
                        {"Last Score: " + playerOneStats.last}
                      </Text>

                      <Text style={{fontSize: containerWidth / 30, color: "white"}}>
                        {"Darts (Leg): " + dartsInThisLegPlayerOne}
                      </Text>

                      <Text style={{fontSize: containerWidth / 30, color: "white"}}>
                        {"ShortestLeg: " + playerOneScores.shortestLeg}
                      </Text>

                      <Text style={{fontSize: containerWidth / 30, color: "white"}}>
                        {"High Fin: " + playerOneScores.highestFinish}
                      </Text>

                      <View style={{width: '100%', height: '10%', justifyContent: 'space-evenly', alignItems: 'center', flexDirection: 'row'}}>

                        <Text style={{fontSize: containerWidth / 40, color: "white"}}>
                          {"40+: " + playerOneScores.forty}
                        </Text>

                        <Text style={{fontSize: containerWidth / 40, color: "white"}}>
                          {"60+: " + playerOneScores.sixty}
                        </Text>

                      </View>

                      <View style={{width: '100%', height: '10%', justifyContent: 'space-evenly', alignItems: 'center', flexDirection: 'row'}}>

                        <Text style={{fontSize: containerWidth / 40, color: "white"}}>
                          {"80+: " + playerOneScores.eighty}
                        </Text>

                        <Text style={{fontSize: containerWidth / 40, color: "white"}}>
                          {"100+: " + playerOneScores.oneHundred}
                        </Text>

                      </View>

                      <View style={{width: '100%', height: '10%', justifyContent: 'space-evenly', alignItems: 'center', flexDirection: 'row'}}>

                        <Text style={{fontSize: containerWidth / 40, color: "white"}}>
                          {"140+: " + playerOneScores.oneHundredAndForty}
                        </Text>

                        <Text style={{fontSize: containerWidth / 40, color: "white"}}>
                          {"180+: " + playerOneScores.oneHundredAndEighty}
                        </Text>

                      </View>


                    </View>

                    :

                    <View/>
                  }

                  {
                    showStats === true && gameMode !== 0 ?

                    <View style={{width: '33%', height: '100%', alignItems: 'center'}}>

                      <Text style={{fontSize: containerWidth / 26, color: "white", fontWeight: 'bold'}}>
                        {playerTwo}
                      </Text>

                      <Text style={{fontSize: containerWidth / 30, color: "white"}}>
                        {"Avg: " + playerTwoStats.average}
                      </Text>

                      <Text style={{fontSize: containerWidth / 30, color: "white"}}>
                        {"DQ: " + playerTwoStats.doubleQuota + "%"}
                      </Text>

                      <Text style={{fontSize: containerWidth / 30, color: "white"}}>
                        {"Last Score: " + playerTwoStats.last}
                      </Text>

                      <Text style={{fontSize: containerWidth / 30, color: "white"}}>
                        {"Darts (Leg): " + dartsInThisLegPlayerTwo}
                      </Text>

                      <Text style={{fontSize: containerWidth / 30, color: "white"}}>
                        {"ShortestLeg: " + playerTwoScores.shortestLeg}
                      </Text>

                      <Text style={{fontSize: containerWidth / 30, color: "white"}}>
                        {"High Fin: " + playerTwoScores.highestFinish}
                      </Text>

                      <View style={{width: '100%', height: '10%', justifyContent: 'space-evenly', alignItems: 'center', flexDirection: 'row'}}>

                        <Text style={{fontSize: containerWidth / 40, color: "white"}}>
                          {"40+: " + playerTwoScores.forty}
                        </Text>

                        <Text style={{fontSize: containerWidth / 40, color: "white"}}>
                          {"60+: " + playerTwoScores.sixty}
                        </Text>

                      </View>

                      <View style={{width: '100%', height: '10%', justifyContent: 'space-evenly', alignItems: 'center', flexDirection: 'row'}}>

                        <Text style={{fontSize: containerWidth / 40, color: "white"}}>
                          {"80+: " + playerTwoScores.eighty}
                        </Text>

                        <Text style={{fontSize: containerWidth / 40, color: "white"}}>
                          {"100+: " + playerTwoScores.oneHundred}
                        </Text>

                      </View>

                      <View style={{width: '100%', height: '10%', justifyContent: 'space-evenly', alignItems: 'center', flexDirection: 'row'}}>

                        <Text style={{fontSize: containerWidth / 40, color: "white"}}>
                          {"140+: " + playerTwoScores.oneHundredAndForty}
                        </Text>

                        <Text style={{fontSize: containerWidth / 40, color: "white"}}>
                          {"180+: " + playerTwoScores.oneHundredAndEighty}
                        </Text>

                      </View>


                    </View>

                    :

                    <View/>
                  }

                </View>

              </View>

            :

              <View style={{...styles.container, width: Dimensions.get('window').width}}>

                <TouchableOpacity onPress={() => {setStreamingPicked(false), setUid(null)}} style={{position: 'absolute', top: 20, left: 20, width: 50, height: 50, justifyContent: 'center', alignItems: 'center'}}>
                  <AntDesign name="back" size={40} color="white" />
                </TouchableOpacity>

                <View style={{width: '90%', height: containerHeight / 4, justifyContent: 'center', alignItems: 'center'}}>

                  <Text style={{fontSize: containerWidth / 36, color: "white"}}>
                    {"Status of the match: " + status}
                  </Text>

                  <Text style={{fontSize: containerWidth / 20, color: "white", fontWeight: 'bold'}}>
                    {"Best of " + gameLengthLegs + " Legs" + (gameLengthSets > 1 ? (" & Best of " +  gameLengthSets + " Sets") : "")}
                  </Text>

                </View>

                <View style={{width: '100%', height: containerHeight * 1.7, justifyContent: 'center', alignItems: 'center', flexDirection: 'row'}}>

                  <View style={{width: '50%', height: '100%', justifyContent: 'center', alignItems: 'center'}}>

                    <Text style={{fontWeight: 'bold', fontSize: containerWidth / 26, color: "white"}}>
                      {playerOne}
                    </Text>

                    <Text style={{fontWeight: 'bold', fontSize: containerWidth / 26, color: "white"}}>
                      {"Legs: " + legsPlayerOne + (gameLengthSets > 1 ? ("  Sets: " +  setsPlayerOne) : "")}
                    </Text>

                    <View style={{flexDirection: 'row', alignItems: 'center', alignContent: 'center'}}>
                      <Text style={{fontWeight: 'bold', fontSize: containerWidth / 4, color: "white"}}>
                        {scorePlayerOne}
                      </Text>

                      <View style={{
                        backgroundColor: (isNineDartFinishPossible(scorePlayerOne, dartsInThisLegPlayerOne/3) && gameLength === 501) ? "yellow" : "transparent",
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: (isNineDartFinishPossible(scorePlayerOne, dartsInThisLegPlayerOne/3) && gameLength === 501) ? containerHeight * 0.4 : 0,
                        width: (isNineDartFinishPossible(scorePlayerOne, dartsInThisLegPlayerOne/3) && gameLength === 501) ? containerHeight * 0.4 : 0,
                        paddingBottom: 10,
                        marginTop: 20
                      }}>

                        <Text
                          style={{
                              color: (isNineDartFinishPossible(scorePlayerOne, dartsInThisLegPlayerOne/3) && gameLength === 501) ? "black" : "transparent",
                              fontWeight: "bold",
                              fontSize: (isNineDartFinishPossible(scorePlayerOne, dartsInThisLegPlayerOne/3) && gameLength === 501) ? containerWidth / 8 : 0,
                              paddingHorizontal: (isNineDartFinishPossible(scorePlayerOne, dartsInThisLegPlayerOne/3) && gameLength === 501) ? 2 : 0,
                              textAlign: 'center',
                              marginLeft: 5,
                          }}
                          >
                              9
                          </Text>

                      </View>

                    </View>

                    <Text style={{fontWeight: 'bold', fontSize: containerWidth / 26, color: "white"}}>
                      {"AVG: " + playerOneStats.average}
                    </Text>
                  
                  </View>

                  {
                    gameMode === 0 ?

                    <View style={{width: '0%', height: '100%'}} />

                    :

                    <View style={{width: '50%', height: '100%', justifyContent: 'center', alignItems: 'center'}}>

                    <Text style={{fontWeight: 'bold', fontSize: containerWidth / 26, color: "white"}}>
                      {playerTwo}
                    </Text>

                    <Text style={{fontWeight: 'bold', fontSize: containerWidth / 26, color: "white"}}>
                      {"Legs: " + legsPlayerTwo + (gameLengthSets > 1 ? ("  Sets: " +  setsPlayerTwo) : "")}
                    </Text>

                    <View style={{flexDirection: 'row', alignItems: 'center', alignContent: 'center'}}>
                      <Text style={{fontWeight: 'bold', fontSize: containerWidth / 4, color: "white"}}>
                        {scorePlayerTwo}
                      </Text>

                      <View style={{
                        backgroundColor: (isNineDartFinishPossible(scorePlayerTwo, dartsInThisLegPlayerTwo/3) && gameLength === 501) ? "yellow" : "transparent",
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: (isNineDartFinishPossible(scorePlayerTwo, dartsInThisLegPlayerTwo/3) && gameLength === 501) ? containerHeight * 0.4 : 0,
                        width: (isNineDartFinishPossible(scorePlayerTwo, dartsInThisLegPlayerTwo/3) && gameLength === 501) ? containerHeight * 0.4 : 0,
                        paddingBottom: 10,
                        marginTop: 20
                      }}>

                        <Text
                          style={{
                              color: (isNineDartFinishPossible(scorePlayerTwo, dartsInThisLegPlayerTwo/3) && gameLength === 501) ? "black" : "transparent",
                              fontWeight: "bold",
                              fontSize: (isNineDartFinishPossible(scorePlayerTwo, dartsInThisLegPlayerTwo/3) && gameLength === 501) ? containerWidth / 8 : 0,
                              paddingHorizontal: (isNineDartFinishPossible(scorePlayerTwo, dartsInThisLegPlayerTwo/3) && gameLength === 501) ? 2 : 0,
                              textAlign: 'center',
                              marginLeft: 5,
                          }}
                          >
                              9
                          </Text>

                      </View>

                    </View>

                    <Text style={{fontWeight: 'bold', fontSize: containerWidth / 26, color: "white"}}>
                      {"AVG: " + playerTwoStats.average}
                    </Text>
                  
                    </View>
                  }



                </View>

                <View style={{width: '100%', height: containerHeight / 4, justifyContent: 'center', alignItems: 'center'}}>

                  <Text style={{fontSize: containerWidth / 30, color: "white"}}>
                    {"MyDartCoach. Make Training Great Again."/*"Sponsored by " + sponsor*/}
                  </Text>

                </View>

              </View>
      }



    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  x01Container:
  {
    width: containerWidth,
    height: containerHeight,
    backgroundColor: "#DBDCDA",
  },
  outsideBox:
  {
    width: '100%',
    height: '25%',
    backgroundColor: "#222323",
    flexDirection: 'row'
  },
  insideBox:
  {
    width: '100%',
    height: '25%',
    backgroundColor: "#DBDCDA",
    flexDirection: 'row'
  },
  playerBox:
  {
    width: '70%',
    height: '100%',
    alignItems: 'center',
    flexDirection: 'row'
  },
  scoreBox:
  {
    width: '30%',
    height: '100%',
    backgroundColor: "#279021",
    flexDirection: 'row'
  },
  halfScoreBox:
  {
    width: '50%',
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  currentPlayerBox:
  {
    backgroundColor: '#8F2222',
    justifyContent: 'center',
    alignItems: 'center'
  },
  triangle: {
    width: 0,
    height: 0,
    backgroundColor: "transparent",
    borderStyle: "solid",
    borderLeftWidth: 50,
    borderRightWidth: 50,
    borderBottomWidth: 100,
    borderLeftColor: "transparent",
    borderRightColor: "transparent",
    borderBottomColor: "white",
    transform: [{ rotate: "-90deg" }],
  },
});
